import { create_element } from 'src/js/utils';
import './form_steps.scss';

jQuery(document).on('gform_post_render', function(event, form_id, current_page){
    form_steps(form_id, current_page);
});

function form_steps(form_id, current_page){
    
    let container = document.getElementById('gform_'+ form_id);
    if( !container ) return;
    
    container.ff_form_steps_con = container.closest('.ff_form_steps');
    if( !container.ff_form_steps_con ) return;

    // console.log('form_steps', {form_id, current_page, container})
    
    container.step_page = document.getElementById('gform_page_'+ form_id +'_'+ current_page);
    if( !container.step_page ) return;

    container.total_pages = container.querySelectorAll('.gform_page').length;
    
    container.step_page_footer = container.step_page.querySelector('.gform_page_footer');
    
    navigation_buttons(container, current_page);

    progress_bar(container, current_page);
}

function navigation_buttons(container, current_page){

    current_page = parseInt(current_page);
    
    // next button
    if( container.total_pages != current_page ) {
        // exclude next button on last page
        const next_button = create_element({
            class_name: 'step_button next_button',
            render: container.step_page_footer,
            render_method: 'prepend',
        })
        next_button.addEventListener('click', ()=>{
            container.step_page_footer.querySelector('.gform_next_button').click();
        });
    }
    
    // back button
    if( current_page > 1 && show_back_button(container) ) {
        // exclude back button on page 1
        const back_button = create_element({
            class_name: 'step_button back_button',
            render: container.step_page_footer,
            render_method: 'prepend',
        })
        back_button.addEventListener('click', ()=>{
            container.step_page_footer.querySelector('.gform_previous_button').click();
        });
    }
}

function progress_bar(container, current_page){

    if( !show_progress_count(container) ) return;
    
    const progress_indicator = create_element({
        class_name: 'progress_indicator',
        html: '<span class="sep">/</span>',
        render: container.step_page_footer,
        render_method: 'prepend',
    })
    
    // total
    create_element({
        class_name: 'total',
        text: container.total_pages,
        render: progress_indicator,
    })

    // current
    create_element({
        class_name: 'current',
        text: current_page,
        render: progress_indicator,
        render_method: 'prepend',
    })
}

function show_back_button(container){

    if( !container.ff_form_steps_con.dataset.show_back_button
        || container.ff_form_steps_con.dataset.show_back_button == 'false' ) {
        return false;
    }

    return true;
}

function show_progress_count(container){
    
    if( !container.ff_form_steps_con.dataset.show_progress_count
        || container.ff_form_steps_con.dataset.show_progress_count == 'false' ) {
        return false;
    }

    return true;
}